@import '../../../colors.module.scss';

@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../assets/fonts/PlutoSansDPDLight-Web.ttf') format('truetype');
}

.WebFormHeader{
	background-color: $dpd-dark-grey;
	color: var(--Neutral-DPD-black, #414042);
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
}

.WebFormContentHolder{
	display: grid;
	width: 500px;
	margin: 15px;
	font-weight: 600;
}

.WebFormTextField{
	margin-bottom: 15px !important;
	font-weight: 600 !important;
}

.WebFormSelect{
	margin-bottom: 15px;
	font-weight: 600;
}

.WebFormDropzone{
	margin-bottom: 5px;
	border: 2px $dpd-light-grey dashed;
	text-align: center;
}

.WebFormDropzone p{
	margin-bottom: 0px;
	min-height: 40px;

}

.ModalButton,
.ModalButton2 {
	height: 45px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	margin: 4px;
	font-weight: 600;
  	border-radius: 15px;
	
	&:hover {
		cursor: pointer;
	}
}

.ModalButton {
	background-color: $dpd-red;
}

.ModalButton2 {
	background-color: $dpd-dark-grey;
}

.ModalButtonHolder {
	display: flex;
	justify-content: space-evenly;
	margin: 10px;
	font-weight: 600;
}

.ErrorHolder {
    background-color: $dpd-claret;
    color: #FFFFFF;
    font-weight: bold;
    width: 462px;
    line-height: 1.2;
    font-family: 'PlutoSansDPDRegular';
	margin-bottom: 10px;
	font-weight: 600;
}

