.multilink-holder {
    margin: 8px 0;
}

.link-holder {
    margin: 8px 0;
    height: 45px;
    width: 640px;
}

.add-link-button,
.delete-link-button {
    color: #FFF;
    background-color: #DC0032;
    font-size: 1.35em;
    font-weight: bold;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.add-link-button,
.delete-link-button:hover {
    cursor: pointer;
}

.delete-link-button-holder {
    display: flex;
    float: right;
    padding: 8px 0;
}

.link-holder .textbox {
    width: 300px;
    float: left;
    margin-right: 3px;
}

.link-holder .textbox .textbox-input-nofocus {
    width: 295px;
}

.link-holder .textbox .textbox-input-focus {
    width: 280px;
}