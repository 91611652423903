@import '../../colors.module.scss';

.FallbackContent {
    height: 100%;
    background-color: #f2f3f3;
}

.SpinnerDiv {
    margin: auto;
    width: 15rem;
    height: 15rem;
}