@import '../../../../colors.module.scss';


.LoginButton {
    background-color: #a90034 !important;
    border-color: #a90034 !important;
    color: #fff !important;
    border: 0 !important;

    &:hover {
        background-color: #dc0032 !important;
        border-color: #dc0032 !important;
        color: #fff !important; 
        border: 0 !important;
    }
}