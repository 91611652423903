@font-face {
    font-family: PlutoSansDPDRegular;
    src: url('../../../../assets/fonts/PlutoSansDPDRegular-Web.ttf') format('truetype');
}

@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../../assets/fonts/PlutoSansDPDLight-Web.ttf') format('truetype');
}

.applicationToolsHolder {
    margin: 8px;
}

.applicationDescription {
    resize: none;
    outline: none;
    width: 400px;
    border: 1px solid #808285;
    color: #414042;
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;
    min-height: 45px;
}

.applicationToolLabel {
    display: inline-block;
    width: 360px;
}

.applicationToolLongLabel {
    display: inline-block;
    width: 520px;
}

.applicationToolInputs {
    margin: 5px 0;
}

.applicationToolDropDown, .jiraFieldValueDropDown {
    margin: 5px 0;
    width: 280px;
}

.mainButton,
.greyButton {
    width: 227px;
    height: 45px;
    font-family: PlutoSansDPDRegular;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 15px 10px;
    margin-right: 8px;
    float: left;
}

.mainButton {
    background-color: #DC0032;
    color: #FFFFFF;
}

.greyButton {
    background-color: #808285;
    color: #FFFFFF;
}

.mainButton:hover,
.greyButton:hover {
    cursor: pointer;
}

.buttonHolder {
    margin-top: 20px;
    height: 50px;
}

.errorHolder {
    background-color: #A90034;
    color: #FFFFFF;
    font-weight: bold;
    width: 462px;
    line-height: 1.2;
    font-family: 'PlutoSansDPDRegular';
}

.fileUploadContainer {
	margin: 10px 0;
}

.fileUploadInput {
	display: inline-block;
	width: 225px;
	padding: 8px;
	border: 1px solid #808285;
	font-family: PlutoSansDPDLight;
	font-size: 14px;
	color: #414042;
	margin-top: 5px;
	margin-bottom: -5px;
	background-color: #FFFFFF;
}
