@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../../assets/fonts/PlutoSansDPDLight-Web.woff');
}

@font-face {
    font-family: PlutoSansDPDRegular;
    src: url('../../../../assets/fonts/PlutoSansDPDRegular-Web.woff');
}

.dropdown-description {
    color: #414042;
    font-family: PlutoSansDPDRegular;
    font-size: 13px;
    line-height: 16px;
    margin: 0px 8px;
}

.dropdown-holder,
.dropdown-list-item {
    background-color: #FFFFFF;
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 16px;
}

.dropdown-holder {
    color: #414042;
    width: 270px;
    padding: 15px 10px 13px 10px;
    border: 1px solid #808285;
}

.dropdown-list-item-holder {
    width: 265px;
    background-color: #FFFFFF;
}

.dropdown-list-item-holder-few {
    background-color: #FFFFFF;
}

.dropdown-list {
    width: 270px;
    margin: 1px 0 0 1px;
    max-height: 235px;
    min-height: 95px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FFFFFF;
    border: 1px solid #808285;
}

.dropdown-list-item {
    color: #414042;
    padding: 15px 0 13px 0;
    width: 245px;
    margin: auto;
    height: 45px;
    border-bottom: 1px solid #E6E7E8;
}

.dropdown-list-item:hover,
.dropdown-list-item-holder-few:hover,
.dropdown-list-item-holder:hover,
.dropdown-list-item-holder:hover .dropdown-list-item,
.dropdown-list-item-holder-few:hover .dropdown-list-item {
    background-color: #DC0032;
    color: #FFFFFF;
    border-bottom: 1px solid #DC0032;
    cursor: pointer;
}

.dropdown-list-item:hover,
.dropdown-list-item-holder:hover .dropdown-list-item,
.dropdown-list-item-holder-few:hover .dropdown-list-item {
    height: 44px;
}

.input-holder-open,
.dropdown-input-open {
    color: #414042;
}

.dropdown-input {
    color: #808285;
}

.image-holder,
.input-holder,
.input-holder-open {
    display: inline-block;
}

.input-holder,
.input-holder-open {
    width: 240px;
}

.arrow-up,
.arrow-down {
    position: relative;
    top: 3px;
}

.arrow-up:hover,
.arrow-down:hover {
    cursor: pointer;
}

.arrow-down {
    transform: rotate(90deg);
}

.arrow-up {
    transform: rotate(270deg);
}

.input-holder,
.dropdown-holder {
    cursor: pointer;
}

.dropdown-title {
    padding: 4px 0 4px 0px;
    font-size: 11px;
    line-height: 16px;
}

.dropdown-filled,
.image-holder-filled {
    display: inline-block;
}

.image-holder-filled {
    position: relative;
    top: -10px;
}

.dropdown-holder-filled {
    padding: 0 10px 4px 10px !important;
}