@import '../../../../../colors.module.scss';

.Link {
	display: inline-block;
	width: 33%;
	text-align: left;
	color: $dpd-black;
	font-weight: 600;

	div {
		display: flex;
		align-items: center;
	}

	span {
		font-size: 14px;
		line-height: 1.5;
		white-space: nowrap;
	}

	&:hover {
		color: $dpd-red;
		transition: color .15s ease-in-out;
		cursor: pointer;
	}
}

.Text {
    font-size: 15px;
}

.Icon{
	width: 28px;
	margin-right: 9px;
	margin-left: -3px;
}

.ForwardArrow {
    border: solid $dpd-black;
    margin: -1px;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}