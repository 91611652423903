@import '../../../../colors.module.scss';

.FilterCommon {
    display: inline-block;
    border: 0;
    height: 30px;
    font-size: 13px;
    padding: 5px 10px;
    margin: 5px 10px 5px 0;
	border-radius: 7px;
	background: #808285
}

.FilterButton {
    background-color: #C3C2C4;
    border-color: #C3C2C4;
    color: var(--Neutral-DPD-black, #414042);
    font-weight: 600;
    cursor: pointer;

    &:hover {
        background-color: $dpd-red;
        border-color: $dpd-red;
        color: white;
        transition: color .15s ease-in-out,
                    background-color .15s ease-in-out,
                    border-color .15s ease-in-out,
                    box-shadow .15s ease-in-out; 
    }
}

.ButtonSelected {
    background-color: $dpd-red;
    border-color: $dpd-red;
    color: white;
	cursor: pointer;
}