@import '../../../colors.module.scss';

.Footer {
	width: 100%;
	min-height: 60px;
	background: #fff;
	padding: 0 35px 0 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: auto;
}

.Copyright {
	font-size: 12px;
}

.FooterLeft {
	display: inline-block;
}

.Logo {
	margin-right: .5em;
	height: 2.5rem;
}

.FooterRight {
	display: inline-block;
	text-align: right;
	font-size: 13px;
}

.Link {
	&:hover {
		cursor: pointer;
	}
}

@media screen and (min-width: 800px) {
	.Footer {
		bottom: 0;
	}
}

@media screen and (max-width: 799px) {
	.Footer {
		padding: 17px 11px 0 16px;
	}

	.FooterLeft { 
		width: 75%;
	}

	.FooterRight {
		width: 25%;
	}
}