@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../../assets/fonts/PlutoSansDPDLight-Web.woff');
}

.inactive-textbox {
    border: 1px solid #ABABAC;
    height: 45px;
    width: 400px;
    background-color: #E6E7E8;
}

.inactive-textbox:hover {
    cursor: not-allowed;
}

.inactive-textbox-content {
    outline: none;
    border: none;
    color: #414042;
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 16px;
    padding: 14px 10px 12px 10px;
}