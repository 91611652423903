.content-holder {
    margin: 0 3vw;
    max-width: 1800px;
    padding-bottom: 60px;
}

.MuiTableCell-root.MuiTableCell-body {
    padding: 2px !important;
    font-size: 0.83rem !important;
    font-family: inherit !important;
    font-weight: normal !important; 
    line-height: 1.2 !important;
    letter-spacing: unset !important;
}

.MuiTableCell-root.MuiTableCell-head {
    padding: 5px 8px;
    background-color: #DC0032;
    border-right: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: 'PlutoSansDPDRegular';
    font-weight: bold;
}

.table-app-link {
    margin-bottom: 2px;
}

.table-edit-button {
    background-color: #808285;
    color: #FFFFFF;
    text-align: center;
    padding: 5px;
    width: 50px;
    margin-right: 5px;
}

.table-delete-button {
    color: #FFFFFF;
    background-color: #DC0032;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    width: 25px;
    height: 25px;
    padding: 3px;
}

.table-edit-button,
.table-delete-button {
    float: left;
}

.table-edit-button:hover,
.table-delete-button:hover {
    cursor: pointer;
}

.main-button,
.gray-button {
    margin-right: 8px;
    float: left;
}

.delete-item {
    font-size: 1.5rem;
    font-weight: bold;
    color: #DC0032;
}

.modal-dialog div.modal-content {
    width: 510px;
}

.table-action-holder {
    width: 80px;
    margin: auto;
}

.secondary-button {
    background-color: #DC0032;
    color: #FFFFFF;
    font-family: 'PlutoSansDPDRegular';
    height: 45px;
    padding: 14px 0;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    width: 260px;
    margin: 8px 0;
}

.secondary-button:hover {
    cursor: pointer;
}

.actions-holder {
    height: 50px;
    margin: 10px 0;
}

.actions-holder .main-button,
.actions-holder .grey-button {
    font-size: 17px;
}

.actions-holder .main-button {
    margin-left: 0;
    margin-bottom: 10px;
}

.actions-holder .grey-button {
    margin-left: 0;
    margin-bottom: 20px;
}

.bu-table {
    width: 700px;
}

.welcome-text {
    margin-top: 40px;
    margin-bottom: 25px;
}

.title-holder {
    margin: 15px 0;
}

.wrap-url {
	word-wrap: break-word;
	max-width: 400px;
}

.wrap-description {
	word-wrap: break-word;
	max-width: 300px;
}

.wrap-supportUrl {
	word-wrap: break-word;
	max-width: 300px;
}