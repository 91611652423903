@import '../../../../../../colors.module.scss';

.Link {
    cursor: pointer;
    padding: 8px 10px;
    font-size: 13px;

    &:hover {
        background-color: $dpd-red;
        color: white;
    }
}