@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../../assets/fonts/PlutoSansDPDLight-Web.woff');
}

@font-face {
    font-family: PlutoSansDPDRegular;
    src: url('../../../../assets/fonts/PlutoSansDPDRegular-Web.woff');
}

.main-button,
.grey-button {
    width: 227px;
    height: 45px;
    font-family: PlutoSansDPDRegular;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 15px 10px;
    margin-right: 8px;
    float: left;
}

.main-button {
    background-color: #DC0032;
    color: #FFFFFF;
}

.grey-button {
    background-color: #808285;
    color: #FFFFFF;
}

.main-button:hover,
.grey-button:hover {
    cursor: pointer;
}

.button-holder {
    margin-top: 20px;
    height: 50px;
}

.MuiAutocomplete-root {
    width: 400px;
    margin-top: 8px;
    outline: none;
    border: 1px solid #808285;
    background-color: #FFFFFF;
}

.MuiFormLabel-root {
    font-family: PlutoSansDPDRegular;
    font-size: 14px;
    line-height: 17px;
    color: #808285;
    background-color: #FFFFFF;
}

.MuiFormLabel-root.Mui-focused {
    color: #808285 !important;
}

.MuiInputBase-root {
    background-color: #FFFFFF !important;
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:after {
    border: none !important;
}

.MuiChip-root {
    background-color: #DC0032 !important;
    font-family: PlutoSansDPDLight !important;
    color: #FFFFFF !important;
    border: none !important;
}

.MuiSvgIcon-root.MuiChip-deleteIcon {
    fill: #FFFFFF !important;
}

.MuiAutocomplete-option {
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 17px;
    color: #808285;
}

.error-holder {
    background-color: #A90034;
    color: #FFFFFF;
    font-weight: bold;
    width: 462px;
    line-height: 1.2;
    font-family: 'PlutoSansDPDRegular';
}