@import '../../../../colors.module.scss';

.AlertBar {
	background-color: #dc0032;
	color: white;
	padding: 5px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	top: 60px;
	width: 100%;
	z-index: 1000;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	font-family: Arial, sans-serif;
	font-size: 14px;
}

.AlertMessage {
	margin-right: 20px;
	text-align: left;
}

.AlertMessage a {
	color: white;
	text-decoration: underline;
}

.AlertClose {
	background: none;
	border: none;
	color: white;
	font-size: 15px;
	cursor: pointer;
	margin-right: 58px;
}

.AlertClose:hover {
	color: #ddd;
}