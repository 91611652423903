@import '../../../../../colors.module.scss';

.AccessRequestIcon {
	display: inline-block;
	width: 33%;
	text-align: left;
	color: $dpd-black;
	font-weight: 600;

	div {
		display: flex;
		align-items: center;
	}

	span {
		font-size: 14px;
		line-height: 1.5;
		white-space: nowrap;
	}

	&:hover {
		color: $dpd-red;
		transition: color .15s ease-in-out;
		cursor: pointer;
	}
}

.AccessRequestPictureClass {
	width: 25px;
	margin-right: 8px;
}