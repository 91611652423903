@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../../assets/fonts/PlutoSansDPDLight-Web.woff');
}

.textbox,
.textbox-error {
    height: 45px;
    width: 400px;
    background-color: #FFFFFF;
}

.textbox {
    border: 1px solid #808285;
}

.textbox-error {
    border: 1px solid #DC0032;
}

.textbox-input-nofocus {
    outline: none;
    border: none;
    color: #808285;
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 16px;
    padding: 14px 10px 12px 10px;
    width: 395px;
}

.textbox-input-focus,
.textbox-input-focus-error {
    color: #414042;
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 16px;
    border: none;
    outline: none;
    padding-right: 4px;
}

.textbox-input-focus {
    width: 380px;
}

.textbox-input-focus-error {
    width: 210px;
}

.textbox-description-error,
.textbox-description {
    font-family: PlutoSansDPDLight;
    font-size: 11px;
    line-height: 16px;
    padding: 3px 10px 1px 10px;
}

.textbox-description {
    color: #808285;
}

.textbox-description-error {
    color: #DC0032;
}

.textbox-input-holder {
    margin-left: 10px;
}

.textbox-input-focus::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.5em;
    width: 1.5em;
    background: url('../../../../assets/pictoset/Delete_Cancel_blackred_pos_rgb.svg') no-repeat 50% 50%;
    background-position-y: -2px;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
  }
  
  .textbox-input-focus:focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
  }

  .textbox-placeholder {
    color: #808285;
    font-family: PlutoSansDPDLight;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    padding: 15px 10px;
  }