@import '../../../../colors.module.scss';

.InputField {
    input {
        height: 45px;
        width: 265px;
        font-size: 13px;
		border-bottom-left-radius: 4px;
		border-top-left-radius: 4px;
        border: 0;
        padding: 5px 15px;

        &:focus {
            outline: none;
            border: 1px solid $dpd-red;
            border-radius: 0;
        }
    }
}

.InputButton,
.ToggleFilterButtons {
    height: 45px;
    width: 46px;
    float: left;
    cursor: pointer;
}

.InputButton {
    background-color: $dpd-red;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
    background-image: url('../../../../assets/pictoset/Search_trans_neg_rgb.svg');
}

.ToggleFilterButtons {
    background-image: url('../../../../assets/pictoset/Settings_rgb.svg');
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 16px 3px;
}

.ListContainer {
    width: 265px;
    box-shadow: 0 0 8px #ddd;
    height: auto;
    max-height: 35vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    z-index: 10;
}

.ListItem {
    list-style: none;
    padding: .5em;
    font-family: inherit;
    font-size: 1em;
    cursor: pointer;
}

.ListItem:hover {
    color: #fff;
    background-color: $dpd-red;
}

@media screen and (min-width: 1311px) {
    .ToggleFilterButtons {
        display: none;
    }
    
    .InputGroup {
        margin: 15px 30px 10px 0;
        display: inline-block;
    }
    
    .InputField,
    .InputButton {
        display: inline-flex;
    }

    .InputButton {
        float: right;
    }

    .Collapse {
        display: none;
    }
}

@media screen and (min-width: 800px) and (max-width: 1310px) {
    .InputGroup {
        margin: 5px 0;
        display: inline-block;
    }
    
    .ToggleFilterButtons {
        display: none;
    }

    .InputField,
    .InputButton {
        display: inline-flex;
    }

    .InputButton {
        float: right;
    }

    .Collapse {
        display: none;
    }
}

@media screen and (max-width: 799px) {
    .InputGroup {
        height: 45px;
        margin: 5px 0 10px 0;
    }

    .InputField {
        float: left;
        input {
            width: 238px;
        }
    }
}