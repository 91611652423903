@font-face {
	font-family: 'DPDFont_Thin';
	src: url('./assets/fonts/PlutoSansDPDThin-Web.woff') format('woff');
	/* font-weight: 100; */
	font-style: normal;
}

@font-face {
	font-family: 'DPDFont_Extra_Light';
	src: url('./assets/fonts/PlutoSansDPDExtraLight-Web.woff') format('woff');
	font-style: normal;
}

@font-face {
	font-family: 'DPDFont_Light';
	src: url('./assets/fonts/PlutoSansDPDLight-Web.woff') format('woff');
	font-style: normal;
}

@font-face {
	font-family: 'DPDFont_Regular';
	src: url('./assets/fonts/PlutoSansDPDRegular-Web.woff') format('woff');
	font-style: normal;
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: 'DPDFont_Light';
	background-color: #f2f3f3;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #e6e7e7;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #dc0032;
}
