@import '../../../../colors.module.scss';

@font-face {
    font-family: PlutoSansDPDLight;
    src: url('../../../../assets/fonts/PlutoSansDPDLight-Web.ttf') format('truetype');
}


.BannerFieldHolder{
	display: grid;
}

.BannerConfigTextField{
	width: 500px;
	margin-top: 5px !important;
	margin-bottom: 5px !important;
	margin-right: 10px !important;
}

.BannerCheckbox{
    width: 40px;
}

.BannerPreview{
	margin-top: 15px;
	margin-bottom: 5px;
	font-size: 21px;
}

.BannerHolder {
	display: flex;
	flex-direction: column;
}

.ErrorHolder {
    background-color: $dpd-claret;
    color: #FFFFFF;
    font-weight: bold;
    width: 462px;
    line-height: 1.2;
    font-family: 'PlutoSansDPDRegular';
}

.BannerContainer{
    display: inline-block;
}

@media screen and (max-width: 1059px) {
    .BannerContainer{
        display: none;
    }
}

@media screen and (max-width: 799px) {
    .BannerFieldHolder{
        display: block;
		margin-top: 50px;
    }
}