
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #808285 !important;
}

.selector__control {
    border-color: #808285 !important;
}

.selector__placeholder {
    color: #808285;
}
.selector__option,
.selector__multi-value__label {
    color: #414042;
}

.selector__multi-value__remove:hover {
    background-color: #DC0032 !important;
    cursor: pointer;
}

.selector__control--is-focused,
.selector__control--menu-is-open {
    border-color: unset !important;
    box-shadow: unset !important;
}

.selector__option:hover,
.selector-inner-option:hover,
.selector__option--is-focused {
    background-color: #DC0032 !important;
    color: #FFFFFF !important;
    cursor: pointer;
}

.selector-inner-option--is-selected,
.selector__option--is-selected{
    color: black !important;
    background-color: #FFFFFF !important;
}





