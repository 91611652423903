@import '../../../../colors.module.scss';

.CardStyle {
	color: var(--Neutral-DPD-black, #414042);
	display: flex;
	flex-direction: column;
	background-color: #ffffff;
	border: 1px solid #ddd;
	border-radius: 20px;
	width: calc(25% - 87px);
	margin-bottom: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	height: 350px;
	overflow: hidden;
}

.CardTitle {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--Spacing-12, 12px);
	padding-left: 17px;
	gap: 10px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	min-height: 48px;
	line-height: 24px;
	color: #414042;
	background-color: #C3C2C4;
}

.TitleText {
	flex: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
	line-height: 24px;
	min-height: 48px;
	display: flex;
	align-items: center;
	font-weight: 600;
}

.TitleIcon {
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		font-size: 15px;
		line-height: 1.5;
		white-space: nowrap;
		font-weight: 600;
		margin-left: 6px;
	}
}

.Info_picture {
	width: 29px;
}

.SupportContainer {
	display: inline-block;
	width: 33%;
	text-align: left;
	color: $dpd-black;
	font-weight: 600;

	div {
		display: flex;
		align-items: center;
	}

	span {
		font-size: 14px;
		line-height: 1.5;
		white-space: nowrap;
	}

	&:hover {
		color: $dpd-red;
		transition: color .15s ease-in-out;
		cursor: pointer;
	}
}

.SupportIcon {
	width: 36px;
	margin-right: 4px;
	margin-left: -7px;
}

.SupportText {
	font-size: 15px;
	font-weight: 600;
}

.CardText {
	flex-grow: 1;
	padding: 10px 14px;
	text-align: left;
	font-size: 13px;
	margin-bottom: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	line-height: 1.2;
	flex: 1;
}

.CardBottom {
	display: flex;
	flex-direction: column;
	padding: 0;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	width: 100%;
	min-height: 90px;
	justify-content: flex-end;
}

.BottomSection {
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
	border-top: 1px solid #ddd;
	width: 100%;
}

.BottomSection:first-child {
	border-top: none;
}

.SplitContent {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.LeftSplit {
	flex: -0.7;
	text-align: left;
}

.RightSplit {
	text-align: right;
}

.Arrow_logo {
	display: flex;
	align-items: flex-start;
	gap: 5px;
	text-align: left;
	color: $dpd-red;
	font-size: 13px;
	font-weight: 600;
}

.Arrow_picture {
	width: 35px;
	height: 35px;
	flex-shrink: 1;
}


@media screen and (max-width: 1700px) {
	.CardStyle {
		width: calc(24% - 40px);
	}
}
@media screen and (min-width: 1201px) and (max-width: 1500px) {
	.CardStyle {
		width: calc(30% - 55px);
	}
}

@media screen and (max-width: 1200px) {
	.CardStyle {
		width: calc(30% - 40px);
	}
}

@media screen and (max-width: 900px) {
	.CardStyle {
		width: calc(42% - 35px);
	}
}

@media screen and (max-width: 600px) {
	.CardStyle {
		width: calc(65% - 25px);
	}
}