@import '../../../colors.module.scss';

.ContentStyle {
	margin: 0;
	overflow-x: hidden;
	background-color: #f2f3f3;
}

.FooterStyle {
	min-height: 40px;
	background: $dpd-light-brown;
	font-size: 0.8em;
	padding-left: 15px;
	padding-right: 15px;
}

.SiteContent {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.UpperRow {
	margin: 30px 3vw;
	background-color: #f2f3f3;
}

.WelcomeText {
	margin-top: 40px;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 42px;
	margin-bottom: 25px;
	width: 833px;
	display: block;
	word-wrap: break-word;
}

.CenteredContainer {
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	h1 {
		font-weight: 300;
	}
}

.SideBar {
	position: absolute;
	transition: width 0.1s;
	z-index: 10;
	background: white;
	height: 100%;
}

.SpinnerDiv {
	margin: auto;
	width: 15rem;
	height: 15rem;
	margin-left: 35rem;
	margin-top: 5rem;
	color: $dpd-red;
}

.OverlaySpinner {
	width: 15rem;
	height: 15rem;
	color: $dpd-red;
}

.BannerContainer {
	display: flex;
	align-content: center;
	justify-content: center;
	margin-top: 15px;
	background-position: center;
	height: 150px;
}

.MaxContentWidth {
	max-width: 1560px;
	margin-bottom: 3vw;
}

.MainContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 20px;
}

.ContentItemsDiv {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	max-height: 700px;
	width: 90%;
	margin: 0;
	overflow-y: auto;
	padding-right: 10px;
}

.SideContainer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 25%;
	gap: 20px;
}

.NewsletterContainer {
	padding: 10px;
	background-color: white;
	text-align: left;
	border-radius: 10px;
	position: relative;
}

.NewsletterHeader {
	text-align: left;
}

.NewsletterHeader h3 {
	color: var(--Neutral-DPD-black, #414042);
	margin: 0;
	font-size: 16px;
	font-weight: 600;
}

.Separator {
	position: absolute;
	left: 0;
	right: 0;
	top: 40px;
	height: 2px;
	background-color: #f2f3f3;
}

.NewsletterContent {
	text-align: left;
	padding-top: 25px;
}

.NewsletterContent a {
	color: $dpd-red;
	font-size: 16px;
	font-weight: 600;
	text-decoration: underline;
	display: inline-flex;
	align-items: center;
}

.Arrow {
	text-decoration: none;
	font-weight: normal;
	color: $dpd-red;
}

.AccessRequestContainer {
	padding: 10px;
	background-color: white;
	text-align: left;
	border-radius: 10px;
	position: relative;
}

.AccessRequestTitle {
	color: var(--Neutral-DPD-black, #414042);
	padding: 8px 2px;
	text-align: left;
	font-size: 16px;
	font-weight: 600;
}

.AccessRequestTitleBorder {
	height: 5px;
	border-bottom: 2px #DCDCDC solid;
	width: 110%;
	position: absolute;
	margin-left: -11px;

}

.AccessRequestsWrapper {
	width: 375px;
}

.AccessRequestCardApproved, .AccessRequestCardOngoing, .AccessRequestCardAccessRequested .AccessRequestCardEmpty {
	background: white;
	padding: 1px 5px;
	line-height: normal;
	margin-top: 5px;
	font-size: 14px;
}

.AccessRequestCardApproved:last-child,
.AccessRequestCardOngoing:last-child,
.AccessRequestCardAccessRequested:last-child,
.AccessRequestCardEmpty:last-child,
.AccessRequestCardApproved:only-child,
.AccessRequestCardOngoing:only-child,
.AccessRequestCardAccessRequested:only-child,
.AccessRequestCardEmpty:only-child {
	border-bottom: none;
}

.AccessRequestCardApproved {
	padding-bottom: 5px;
	border-bottom: 1px #DCDCDC solid;
}


.AccessRequestCardEmpty {
	border-bottom: 1px #DCDCDC solid;
	padding: 1px;
}

.AccessRequestCardApproved label, .AccessRequestCardOngoing label, .AccessRequestCardAccessRequested label {
	margin-bottom: 0px;
	overflow-wrap: anywhere;
}

.AccessRequestCardContent {
	display: flex;
	justify-content: space-between;
	align-items: center;

	label {
		white-space: normal;
		max-width: 100%;
		flex-grow: 2;
		margin: 5px 5px -2px 0;
	}
}


.AccessRequestStatus {
	padding: 5px 0;
	color: var(--Neutral-DPD-black, #414042);
	border-radius: 10px;
	font-weight: bold;
	text-align: center;
}

.StatusApproved {
	border-radius: 10px;
	padding: 5px 7px;
	background-color: #C1DEBA;
}


.StatusAccessRequested {
	border-radius: 10px;
	padding: 5px 7px;
	background-color: #EDEDED;
}

.StatusOngoing {
	border-radius: 10px;
	padding: 5px 7px;
	background-color: #FFB946;
}


.LoadingOverlay {
	background: #808285;
	position: fixed;
	opacity: 0.7;
	z-index: 100;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center
}

@media screen and (max-width: 1600px) {

	.MainContainer {
		flex-direction: column;
		align-items: stretch;
		width: 100%
	}

	.BannerContainer {
		width: 100%;
	}

	.ContentItemsDiv {
		width: 90%;
	}

	.SideContainer {
		width: 50%;
	}

	.AccessRequestTitleBorder {
		width: 100%;
	}

	.Separator {
		width: 100%;
	}
}

@media screen and (max-width: 1200px) {

	.BannerContainer {
		width: 100%;
	}

	.UpperRow {
		width: 100%;
	}

	.MainContainer {
		flex-direction: column;
		align-items: stretch;
	}

	.ContentItemsDiv {
		width: 100%;
	}

	.SideContainer {
		width: 50%;
	}

	.AccessRequestTitleBorder {
		width: 100%;
	}

	.Separator {
		width: 100%;
	}
}

@media screen and (max-width: 800px) {

	.BannerContainer {
		width: 100%;
	}

	.ContentItemsDiv {
		width: 90%;
	}

	.SideContainer {
		width: 60%;
	}

	.AccessRequestTitleBorder {
		width: 100%;
	}

	.Separator {
		width: 100%;
	}

	.WelcomeText {
		font-size: 20px;
		text-align: left;
		width: 90%;
	}

	.AccessRequestsWrapper {
		width: 100%;
	}
}