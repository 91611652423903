.MuiAlert-standardError {
    background-color: #a90034 !important;
    color: white !important;
}

.MuiAlert-standardError .MuiAlert-icon {
    color: white !important;
}

.MuiAlert-standardSuccess {
    background-color: #0aa021 !important;
    color: white !important;
}

.MuiAlert-standardSuccess .MuiAlert-icon {
    color: white !important;
}