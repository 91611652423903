@import '../../../../../colors.module.scss';

.SSO_logo {
	display: flex;
	align-items: flex-start;
	gap: 5px;
	text-align: left;
	color: $dpd-red;
	font-size: 13px;
	font-weight: 600;
}

.SSO_picture {
	width: 35px;
	height: 35px;
	flex-shrink: 1;
}

.SSO_text {
	font-size: 13px;
	color: $dpd-red !important;
	vertical-align: middle;
	white-space: nowrap;
}

.Arrow_logo {
	display: flex;
	align-items: flex-start;
	gap: 5px;
	text-align: left;
	color: $dpd-red;
	font-size: 13px;
	font-weight: 600;
}

.Arrow_picture {
	width: 35px;
	height: 35px;
	flex-shrink: 1;
}