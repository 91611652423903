@font-face {
    font-family: PlutoSansDPDRegular;
    src: url('../../../../assets/fonts/PlutoSansDPDRegular-Web.woff');
}

.main-button,
.grey-button {
    width: 227px;
    height: 45px;
    font-family: PlutoSansDPDRegular;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 15px 10px;
    margin-right: 8px;
    float: left;
}

.main-button {
    background-color: #DC0032;
    color: #FFFFFF;
}

.grey-button {
    background-color: #808285;
    color: #FFFFFF;
}

.main-button:hover,
.grey-button:hover {
    cursor: pointer;
}

.button-holder {
    margin-top: 20px;
}