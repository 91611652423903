@import '../../../colors.module.scss';

.HeaderContainer {
	position: relative;
}

.HeaderStyle {
	border-bottom: 1px solid $dpd-light-grey;
	background-color: white;
	color: $dpd-black;
	width: 100%;
	height: 60px;
	z-index: 1;
	display: flex;
	justify-content: space-between;
}

.HeaderLeft,
.HeaderRight {
	display: flex;
	align-items: center;
	padding: 0 15px;
}

.HeaderStyle img {
	height: 38px;
}

.HeaderSpacer {
	font-size: 18px;
	font-weight: 600;
	padding: 0 10px;
}

.HeaderRight {
	justify-content: flex-end;
}

.UserName {
	display: inline-block;
}

.LogoutLink {
	display: inline-block;
	padding: 0 20px 0 0;
	color: $dpd-red;
	font-weight: 600;
	cursor: pointer;
}

.AdminButton {
	display: inline-block;
	margin-left: 10px;
}

.AdminButton:hover {
	cursor: pointer;
}

@media screen and (min-width: 800px) {
    .HeaderStyle {
        position: sticky;
        top: 0;
    }

    .HeaderLeft {
        display: flex;
    }

    .HeaderRight {
        justify-content: end;
    }
}

@media screen and (max-width: 799px) {
    .HeaderLeft {
        float: left;
        display: flex;
    }

    .HeaderRight {
        padding: 5px 15px;
        display: flex;
        flex-direction: column;
    }

    .HeaderSpacer {
        display: none;
    }

    .LogoutLink {
        display: block;
        padding: 0;
    }
}